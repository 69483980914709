/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { IStep2, Wizard2 } from '../components/wizard';
import { useAppDispatch } from '../app/hooks';
import { updateSetting, updateType } from '../slices/submitSlice';
import CommonLayout from './layout/commonLayout';
import Settings from '../components/steps/common/step_setting';
import Shape from '../components/steps/common/step_shape';
import Carat from '../components/steps/common/step_weight';
import StepPhotoUpload from '../components/steps/common/step_photoUpload';
import StepRegistration from '../components/steps/common/step_registration';
import SinglePageFlow from '../components/steps/common/single_page_flow';
import { loadGraph } from '../lib/wizard';
import StepReject from '../components/steps/common/step_reject';
import StepSubmit from '../components/steps/common/step_submit';
import { EXPERIMENTS, isRingWithoutSettingExperiment, variantIsLoaded } from '../lib/experiment';
import { Styles } from '../data/ItemDetails';

const createSteps = ({ singlePageFlow = false, withoutSetting = false }): IStep2[] => [
  ...(singlePageFlow
    ? [
        {
          comp: SinglePageFlow,
          name: 'SinglePageSubmission',
          caption: '',
          title: 'Tell us about your ring',
          disableBackButton: true,
          staticProgressBar: true,
          disappearFromProgressBar: true,
          disableProgressBar: true,
          props: { fontSize: '42px', fontFamily: 'Playfair Display' },
          exits: {
            next: 'StepSubmit',
          },
        },
      ]
    : []),
  ...(withoutSetting
    ? []
    : [
        {
          comp: Settings,
          name: 'StepSettings',
          caption: 'Ring Setting',
          title: 'What is the setting of your ring?',
          disableBackButton: true,
          exits: {
            next: 'StepShape',
          },
        },
      ]),
  {
    comp: Shape,
    name: 'StepShape',
    caption: 'Center Diamond Shape',
    title: 'What is the shape of your center diamond?',
    disableBackButton: false,
    exits: {
      next: 'StepCarat',
    },
  },
  {
    comp: Carat,
    name: 'StepCarat',
    caption: 'Center Diamond Carat',
    title: 'Great! What is the carat weight of your center diamond?',
    exits: {
      next: 'StepSubmit',
    },
  },
  {
    comp: StepSubmit,
    name: 'StepSubmit',
    caption: 'Submit',
    disableBackButton: true,
    exits: {
      next: 'StepRegistration',
      reject: 'StepReject',
    },
  },
  {
    comp: StepReject,
    name: 'StepReject',
    caption: 'Reject',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {
      restart: withoutSetting ? 'StepShape' : 'StepSettings',
    },
  },
  {
    comp: StepRegistration,
    name: 'StepRegistration',
    caption: 'Register',
    itemRequired: true,
    skipOnlogin: true,
    disableBackButton: true,
  },
  {
    comp: StepPhotoUpload,
    name: 'StepPhotoUpload',
    caption: 'Upload photos',
    disappearFromProgressBar: true,
    disableProgressBar: true,
    disableBackButton: true,
    exits: {},
    final: true,
  },
];

function RingPage() {
  const dispatch = useAppDispatch();
  const isWithoutSetting = isRingWithoutSettingExperiment();
  const steps = createSteps({
    withoutSetting: isWithoutSetting,
  });

  useEffect(() => {
    variantIsLoaded(EXPERIMENTS.ringSetting, isWithoutSetting ? ['1'] : ['0']);
    dispatch(updateType('ring'));
    if (isWithoutSetting) {
      dispatch(updateSetting(Styles.Other));
    }
  }, [dispatch]);

  const stepsGraph = loadGraph(steps);

  return (
    <CommonLayout>
      <Wizard2 stepsGraph={stepsGraph} />
    </CommonLayout>
  );
}

export default RingPage;
